/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';
@import './assets/scss/print.scss';
@import './assets/scss/theme.scss';
@import './assets/scss/icons.scss';
@import './assets/scss/ar.scss';
@import './assets/scss/fonts.scss';
/*
** data tables
*/
@import '../node_modules/@swimlane/ngx-datatable/index.scss';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.p-datatable .p-datatable-header {
    padding-left: 0;
    padding-right: 0;
}
.ngx-datatable .datatable-body-cell {
    box-shadow: 1px 1px 0px 2px white;
    // background: #f5dab1;
    // background: #ffc8c8;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: center;
}
