body[dir='rtl'] {
    direction: rtl;
    bar-rating {
        .br-default .br-fraction:after {
            right: 0;
        }
    }
    .add-new {
        text-align: left;
    }
    .field-checkbox > label,
    .field-radiobutton > label {
        margin-right: 0.5rem;
        margin-left: unset;
    }
    .p-datatable .p-datatable-tbody > tr > td,
    .p-datatable .p-datatable-thead > tr > th {
        text-align: right;
    }
    .p-splitbutton {
        flex-direction: row-reverse;
    }
    etammn-page-head {
        .pi-arrow-left {
            transform: rotate(180deg);
        }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox,
    .p-multiselect-panel .p-multiselect-header .p-checkbox {
        margin-right: unset;
        margin-inline-end: 0.5rem;
    }
}
