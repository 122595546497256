:root {
    // --main-color: #c2272d; // var(--main-color);
    --main-color: #409eff; // var(--main-color);
    --main-color-opacity: #ffe5e6;
    --bs-danger: #e74c3c;
    --bs-success: #27ae60;
    --bs-warning: #f59e0b;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: var(--main-color-opacity);
    color: black;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-button.p-button-danger.p-button-custom,
.p-buttonset.p-button-danger.p-button-custom > .p-button,
.p-splitbutton.p-button-danger.p-button-custom > .p-button,
.p-button.p-button-danger.p-button-custom:enabled:hover,
.p-buttonset.p-button-danger.p-button-custom > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-custom > .p-button:enabled:hover,
.p-button.p-button-custom,
.p-button-custom .p-button,
.p-button-custom .p-button:enabled:hover,
.p-button-custom .p-button:enabled:active,
.dropdown-item.active,
.dropdown-item:active,
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover,
// .p-button
.p-button.p-fileupload-choose,
.p-fileupload-choose:not(.p-disabled):hover,
.p-fileupload-choose:not(.p-disabled):active,
.p-fileupload-choose:not(.p-disabled).p-focus,
.p-fileupload-buttonbar p-button .p-button,
.p-fileupload-buttonbar p-button .p-button:hover,
.p-fileupload-buttonbar p-button .p-button:active,
.p-fileupload-buttonbar p-button .p-button.p-focus,
.p-fileupload-content button,
.p-fileupload-content button:enabled:hover,
.p-fileupload-content button:enabled:active,
.p-fileupload-content button:enabled.p-focus,
p-calendar .p-calendar .p-button,
p-calendar .p-calendar .p-button:hover,
p-calendar .p-calendar .p-button:active,
p-calendar .p-calendar .p-button.p-focus {
    background: var(--main-color);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-steps .p-steps-item.p-highlight .p-steps-number,
.p-button.p-button-danger.p-button-custom.p-button-outlined,
.p-buttonset.p-button-danger.p-button-custom > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger.p-button-custom > .p-button.p-button-outlined,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-button.p-button-danger.p-button-custom.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger.p-button-custom > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger.p-button-custom > .p-button.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-custom.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger.p-button-custom > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger.p-button-custom > .p-button.p-button-outlined:enabled:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: var(--main-color);
}

.p-dropdown:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled):hover,
.p-inputtext:enabled:hover,
.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-button.p-button-danger.p-button-custom,
.p-buttonset.p-button-danger.p-button-custom > .p-button,
.p-splitbutton.p-button-danger.p-button-custom > .p-button,
.p-button.p-button-danger.p-button-custom:enabled:hover,
.p-buttonset.p-button-danger.p-button-custom > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-custom > .p-button:enabled:hover,
.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-button.p-button-custom:enabled:active,
.p-button.p-button-custom:enabled:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
.p-button-custom .p-button,
.p-button-custom .p-button:enabled:hover,
.p-button-custom .p-button:enabled:active,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover,
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover,
// .p-button
.p-button.p-fileupload-choose,
.p-fileupload-choose:not(.p-disabled):hover,
.p-fileupload-choose:not(.p-disabled):active,
.p-fileupload-choose:not(.p-disabled).p-focus,
.p-fileupload-buttonbar p-button .p-button,
.p-fileupload-buttonbar p-button .p-button:hover,
.p-fileupload-buttonbar p-button .p-button:active,
.p-fileupload-buttonbar p-button .p-button.p-focus,
.p-fileupload-content button,
.p-fileupload-content button:enabled:hover,
.p-fileupload-content button:enabled:active,
.p-fileupload-content button:enabled.p-focus,
p-calendar .p-calendar .p-button,
p-calendar .p-calendar .p-button:hover,
p-calendar .p-calendar .p-button:active,
p-calendar .p-calendar .p-button.p-focus {
    border-color: var(--main-color);
}

.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover,
.p-steps .p-steps-item.p-highlight .p-steps-number,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-button.p-button-custom:enabled:active,
.p-button.p-button-custom:enabled:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight,
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    background: var(--main-color-opacity);
}

.p-datatable .p-sortable-column:focus,
.p-button.p-button-danger.p-button-custom:enabled:focus,
.p-button.p-button-danger.p-button-custom:enabled:active,
.p-buttonset.p-button-danger.p-button-custom > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-custom > .p-button:enabled:active {
    box-shadow: inset 0 0 0 0.15rem var(--main-color-opacity);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: inset 0 0 0 0.2rem var(--main-color-opacity);
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-button.p-fileupload-choose.p-focus {
    box-shadow: 0 0 0 0.2rem var(--main-color-opacity) !important;
}

.p-button.p-fileupload-choose:focus,
.p-fileupload-buttonbar p-button .p-button:focus,
.p-fileupload-content button:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--main-color-opacity), 0 1px 2px 0 black;
}

.p-steps {
    .p-steps-item {
        .p-menuitem-link {
            background: transparent !important;
            .p-steps-title {
                color: #343a40 !important;
            }
        }
    }
}

.p-button.p-button-danger.p-button-custom.p-button-outlined,
.p-buttonset.p-button-danger.p-button-custom > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger.p-button-custom > .p-button.p-button-outlined {
    background-color: transparent !important;
}

.p-button.p-button-custom {
    border: 1px solid var(--main-color);
}

.p-button .p-button-icon-left,
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    margin-inline-end: 0.5rem !important;
    margin-right: auto;
}

p-calendar .p-calendar .p-button,
p-calendar .p-calendar .p-button:hover,
p-calendar .p-calendar .p-button:active,
p-calendar .p-calendar .p-button.p-focus {
    color: white;
}

input:disabled {
    opacity: 0.6 !important;
}

.iti:has(> input:disabled) {
    opacity: 0.6 !important;
}
