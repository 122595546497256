@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600&display=swap');

* {
    font-family: 'Cairo', sans-serif;
}

input,
p-dropdown span.p-dropdown-label {
    font-family: 'Cairo', sans-serif !important;
}
