@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    @page {
        size: A4 landscape;
        // size: landscape;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0;
        -webkit-print-color-adjust: exact;
    }
    header,
    footer,
    etammn-side-bar,
    etammn-page-head,
    .p-datatable-header,
    th.operations,
    td.operations,
    .p-paginator {
        display: none !important;
    }
    .content-section {
        width: 100vw !important;
        max-width: 100vw !important;
    }
    th,
    td {
        min-width: unset !important;
    }
}
